body {
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-family: "Microsoft YaHei", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-variant: tabular-nums;
    font-feature-settings: "tnum", "tnum";
}

.bg {
    background-image: url(https://cdnp.h5120.com/Upload/task/invite/bg_repeat0915.jpg);
    background-repeat: repeat-y;
    background-position: center top;
    background-size: contain;
}

.redpacket {
    width: auto;
    height: 720px;
    background-image: url(https://cdnp.h5120.com/Upload/task/invite/taskinv_bag11031.jpg);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% 575px;
}

.redpacket2 {
    width: auto;
    height: 800px;
    background-image: url(https://cdnp.h5120.com/Upload/task/invite/taskinv_bag11032.jpg);
    background-repeat: no-repeat;
    background-position: center -40px;
    background-size: 100% 555px;
}

.btnRule {
    width: 80px;
    height: 38px;
    line-height: 38px;
    background: none;
    color: #E8471F;
    font-size: 14px;
    margin: 0;
    padding: 0;
    border: 0;
    background: linear-gradient(#FFFFFF, #FFE4A4);
    border-radius: 0px 0px 12px 12px;
    outline: none;
}

.btnShare {
    width: 60%;
    height: 50px;
    line-height: 50px;
    background: none;
    border: 0;
    outline: 0;
    color: #F64531;
    font-weight: bold;
    text-align: center;
    font-size: 16px;
    margin: 0 auto;
    background: linear-gradient(#F9DE56, #FEFC86);
    border-radius: 58px;
    -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

    animation-name: mychange;
    /*动画的名字*/
    animation-duration: 500ms;
    /*定义动画完成一个周期所需要的时间，以秒或毫秒计*/
    animation-iteration-count: infinite;
    /*定义动画的播放次数，这里是无限播放*/
    animation-direction: alternate;
    /*定义是否应该轮流反向播放动画，这里是动画轮流播放*/
}

.btnShare span {
    width: auto;
    height: 50px;
    display: block;
    background-image: url(https://cdnp.h5120.com/upload/task/invite/icon_wx.png);
    background-repeat: no-repeat;
    background-position: 15px center;
    background-size: 30px 30px;
    padding-left: 25px;
}

@keyframes mychange {
    0% {
        transform: scale(1)
    }

    100% {
        transform: scale(1.1)
    }
}


.iptRegister {
    width: 80%;
    height: 46px;
    background: none;
    border: 0;
    outline: 0;
    color: #F64531;
    font-weight: bold;
    text-align: center;
    font-size: 18px;
    margin: 10px auto 0 auto;
    display: block;
    text-align: left;
}

.iptRegister .phone {
    width: auto;
    background-image: url(https://cdnp.h5120.com/upload/task/invite/icon_reg_phone.png);
    background-repeat: no-repeat;
    background-position: 15px center;
    background-size: 24px 24px;
    padding-left: 45px;
    background-color: #F79941;
    height: 46px;
    border-radius: 2px;
    display: block;
    position: relative;
}

.iptRegister .pwd {
    width: auto;
    background-image: url(https://cdnp.h5120.com/upload/task/invite/icon_reg_pwd.png);
    background-repeat: no-repeat;
    background-position: 15px center;
    background-size: 24px 24px;
    padding-left: 45px;
    background-color: #F79941;
    border-radius: 2px;
    height: 46px;
    margin-right: 120px;
    position: relative;
}

.iptRegister input {
    width: 100%;
    height: 43px;
    color: #ffffff;
    border: 0;
    outline: none;
    padding: 0;
    margin: 0;
    font-size: 16px;
    background: none;
}

.iptRegister button {
    height: 44px;
    background: none;
    color: #ffffff;
    font-size: 16px;
    border: 1px solid #FCE19F;
    outline: none;
    margin: 0;
    padding: 0;
    border-radius: 2px;
}

.iptRegister input::-webkit-input-placeholder {
    color: #ffffff;
    font-size: 16px;
}

.iptRegister input:-moz-placeholder {
    color: #ffffff;
    font-size: 16px;
}

.iptRegister input::-moz-placeholder {
    color: #ffffff;
    font-size: 16px;
}

.iptRegister input:-ms-input-placeholder {
    color: #ffffff;
    font-size: 16px;
}

.btnDownload {
    width: 80%;
    height: 55px;
    line-height: 55px;
    border: 0;
    outline: 0;
    color: #F64531;
    font-weight: bold;
    text-align: center;
    font-size: 18px;
    margin: 0 auto;
    display: block;
    background: linear-gradient(#FEF2D5, #FFD75E);
    border-radius: 10px;
    -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.invprocess {
    width: auto;
    height: 165px;
    margin: 50px 20px 20px 20px;
}

.invprocess .title {
    width: 100%;
    height: 35px;
    background-image: url(https://cdnp.h5120.com/upload/task/invite/taskinv_inp0915.png);
    background-repeat: none;
    background-position: center top;
    background-size: 100% 35px;
}

.invprocess .content {
    width: 100%;
    height: 130px;
    margin-top: -1px;
    background-color: #ffffff;
    border-radius: 0px 0px 12px 12px;
}

.invprocess .content .imgwrapper {
    height: 50px;
    margin: 0 auto;
    padding:20px 0;
    text-align: center;
}

.invprocess .content .imgwrapper img {
    border: 0;
    vertical-align: middle;
}

.invprocess .content ul.text  {
    margin:0;padding:0;
    width: 100%;
    display: flex;
    flex-direction: row;
}
.invprocess .content ul.text li {
    flex:1;text-align: center;
    list-style: none;
    color: #613616;
    font-size: 13px;
}


.popbox {
    width: 100%;
    height: 100%;
    position: fixed;
    overflow: auto;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 10;
}

.popbox .tips {
    width: 220px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    margin: 0 0 0 -110px;
    color: #ffffff;
    border-radius: 15px;
    position: absolute;
    left: 50%;
    top: 150px;
    z-index: 100;
    background: rgba(0, 0, 0, 0.6);
}

.popbox .loading {
    width: 180px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    margin: 0 0 0 -90px;
    color: #ffffff;
    border-radius: 15px;
    position: absolute;
    left: 50%;
    top: 160px;
    z-index: 101;
    background: rgba(0, 0, 0, 0.5);
}

.popbox .loading .loader {
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid #3498db;
    width: 18px;
    height: 18px;
    margin: 0 10px 0 0;
    display: inline-block;
    vertical-align: middle;
    -webkit-animation: loadingspin 1s linear infinite;
    animation: loadingspin 1s linear infinite;
}

@-webkit-keyframes loadingspin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes loadingspin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.popbox .wrapper {
    width: 330px;
    margin: 50px auto 0 auto;
    position: relative;
}

@media screen and (max-width: 320px) {
    .popbox .wrapper {
        width: 280px;
        margin: 50px auto 0 auto;
    }
}

.popbox .wrapper .top1 {
    width: 100%;
    height: 160px;
    background-image: url(https://cdnp.h5120.com/upload/task/invite/taskinv09156.png);
    background-repeat: none;
    background-position: center top;
    background-size: cover;
    position: relative;
}

.popbox .wrapper  .close {
    width: 30px;
    height: 30px;
    background-image: url(https://cdnp.h5120.com/upload/task/invite/taskinv_clswithdraw09166.png);
    background-repeat: none;
    background-position: center top;
    background-size: cover;
    position: absolute;
    right: 0;
    top: 0;
}

.popbox .wrapper .top2 {
    width: 100%;
    height: 160px;
    background-image: url(https://cdnp.h5120.com/upload/task/invite/taskinv_pop09163.png);
    background-repeat: none;
    background-position: center top;
    background-size: 100% 160px;
    text-align: center;
    position: relative;
    color: #FA662D;
    font-size: 16px;
}

.popbox .wrapper .top3 {
    width: 100%;
    height: 50px;
    line-height: 50px;
    color: #ffffff;
    font-size: 18px;
    text-align: center;
    background-color: #E48F23;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.popbox .wrapper .content {
    width: auto;
    padding: 10px 20px 20px 20px;
    margin-top: -1px;
    background-color: #ffffff;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    position: relative;
}

.popbox .wrapper .content .list {
    margin: 0;
    padding: 0;
}

.popbox .wrapper .content .list ul {
    margin: 0;
    padding: 0;
}

.popbox .wrapper .content .list ul li {
    list-style: decimal;
    padding: 3px;
    margin: 0 0 0 15px;
    line-height: 20px;
}

.popbox .wrapper .content .list ul li strong {
    color: #fa662d;
}

.popbox .wrapper .content .btnpost {
    width: 160px;
    height: 40px;
    background: linear-gradient(#feb054, #ff965b);
    color: #ffffff;
    border-radius: 30px;
    border: 0;
    margin: 15px auto 0 auto;
    display: block;
    outline:none;
}
.popbox .wrapper .content .btninvite {
    width: 100%;
    height: 50px;
    background: linear-gradient(#feb054, #ff965b);
    color: #ffffff;
    font-size: 16px;
    border-radius: 30px;
    border: 0;
    margin: 0 auto;
    display: block;
    outline:none;
}

.popbox .wrapper .content .btnpostDisabled {
    width: 170px;
    height: 40px;
    background: linear-gradient(#eaeaea, #dddddd);
    color: #ffffff;
    border-radius: 30px;
    border: 0;
    margin: 15px auto 0 auto;
    outline: none;
    display: block;
}

.popbox .wrapper .content .btnpostEnable {
    width: 170px;
    height: 40px;
    background: linear-gradient(#FEB054, #FF9457);
    color: #ffffff;
    border-radius: 30px;
    border: 0;
    margin: 15px auto 0 auto;
    display: block;
    outline: none;
}

.tabPanel {
    width: auto;
    min-height: 330px;
    background-color: #ffffff;
    border-radius: 12px;
    margin: 0 20px 0 20px;
    padding: 0 0 10px 0;
}

.tabPanel ul.tabItems {
    margin: 0 20px;
    padding: 0;
    height: 50px;
    display: flex;
    flex-direction: row;
    border-bottom: #ffddcf 1px solid;
}

.tabPanel ul.tabItems li {
    list-style: none;
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    color: #77520D;
    text-align: center;
    flex: 1;
    margin: 0;
    padding: 0;
}

.tabPanel ul.tabItems li.active {
    border-bottom: #fa5b31 2px solid;
    color: #FF5411;
}

.tabPanel .content {
    width: auto;
    padding: 10px 20px 0 20px;
}

.tabPanel .content .list {
    margin: 0;
    padding: 0;
}

.tabPanel .content .list ul {
    margin: 0;
    padding: 0;
}

.tabPanel .content .list ul li {
    list-style: decimal;
    text-align: justify;
    padding: 3px;
    margin: 0 0 3px 15px;
    line-height: 20px;
}

.tabPanel .content .list ul li strong {
    color: #ea9829;
}

.tabPanel .content>p {
    text-align: 'justify';
}

.tabPanel .content>p strong {
    color: #ea9829;
    font-weight: normal;
}


.invpopMoneys{width:auto;height:50px;margin:0;display: flex;flex-direction: row;padding:0;}
.invpopMoneys li{flex:1;list-style: none;}
.invpopMoneys li span{display: block; width: auto; height:50px;line-height: 50px; text-align:center; 
    background-color:#f1f2f3; border-radius: 5px;
    color: #FF4A03; margin: 0 10px;font-size:16px;}

.tabPanel .content>div.withdraws {
    width: auto;
    height: 55px;
    line-height: 55px;
    display: flex;
    flex-direction: row;
    border-bottom: #ffddcf 1px solid;
    color: #666666;
}

.tabPanel .content>div.withdraws i {
    flex: 1;
    display: block;
    font-style: normal;
    padding-left: 10px;
}

.tabPanel .content>div.withdraws i>span.f {
    width: 50px;
    height: 20px;
    line-height: 20px;
    padding-left: 15px;
    display: inline-block;
}

.tabPanel .content>div.withdraws em {
    flex: 2;
    display: block;
    text-align: right;
    font-style: normal;
    padding-right: 10px;
}

.tabPanel .content .more {
    width: 120px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    display: block;
    color: #666666;
    border: 0;
    background: none;
    margin: 10px auto 0 auto;
    outline: none;
}

.tabPanel .content .more .downArrow {
    width: 8px;
    height: 8px;
    display: inline-block;
    content: '';
    border-right: 1.5px solid #666666;
    border-top: 1.5px solid #666666;
    -webkit-transform: rotate(135deg);
    /*箭头方向可以自由切换角度*/
    transform: rotate(135deg);
    margin: 0 0 8px 10px;
    vertical-align: middle;
}

.tabPanel .content .more .upArrow {
    width: 8px;
    height: 8px;
    display: inline-block;
    content: '';
    border-right: 1.5px solid #666666;
    border-top: 1.5px solid #666666;
    -webkit-transform: rotate(-45deg);
    /*箭头方向可以自由切换角度*/
    transform: rotate(-45deg);
    margin: 0 0 0 10px;
    vertical-align: middle;
}

.times {
    width: auto;
    height: 30px;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    color: #ffffff;
}

.times li {
    list-style: none;
    flex: 1;
    text-align: center;
    font-size: 16px;
    line-height: 30px;
}

.times li:nth-child(odd) {
    background-color: #B12211;
    border-radius: 2px;
}

.btnWithdraw {
    width: 65px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    border: 0;
    outline: none;
    background: none;
    color: #ffffff;
    position: absolute;
    z-index: 1;
}

.dd {
    width: 80px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    position: absolute;
    color: #ffffff;
    top: -38px;
    z-index: 10;
    background: #EB5C2A;
    border-radius: 3px 3px 3px 3px;
    background: -webkit-gradient(linear, left top, left bottom, from(#f9d835), to(#f3961c));
    background: -moz-linear-gradient(top, #f9d835, #f3961c);
    background: -o-linear-gradient(top, #f9d835, #f3961c);
}

.process-arrow {
    display: block;
    position: absolute;
    top: -10px;
    width: 0;
    height: 0;
    border-width: 8px 8px 0;
    border-style: solid;
    border-color: #EB5C2A transparent;
}

.withdrawTips {
    width: 70px;
    height: 35px;
    line-height: 25px;
    width: 50px;
    height: 50px;
    border-width: 50px;
    border-style: solid;
    border-color: #09F #990 #933 #0C9;
    position: absolute;
    right: -25px;
    top: -35px;
    color: #ffffff;
    text-align: center;
}

.footerTips {
    width: auto;
    height: 140px;
    margin: 20px 30px 0 30px;
    color: #ffffff;
    font-size: 12px;
}

.footerTips h4 {
    font-size: 12px;
    margin: 0;
    padding: 0 0 3px 0;
    color: #ffffff;
}

.footerTips p {
    margin: 0;
    padding: 0 0 3px 0;
}

.downloadTips {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.6);
}

.downloadTips .tips {
    width: 250px;
    height: 212px;
    background-image: url(https://cdnp.h5120.com/upload/task/invite/downloadtips2.png);
    background-repeat: none;
    background-position: center top;
    background-size: cover;
    position: absolute;
    right: 20px;
    top: 10px;
}

.carousel {
    width: auto;
    height: 30px;
    overflow: hidden;
    position: relative;
}

.carousel>div {
    width: 100%;
    list-style: none;
    height: 30px;
    line-height: 30px;
    color: #700800;
    font-size: 16px;
    text-align: center;
    position: relative;
}

.carousel>div.mv {
    animation: carouselmove 3s infinite;
    -webkit-animation: carouselmove 3s infinite;
    /*Safari and Chrome*/
}

@keyframes carouselmove {
    from {
        top: 0px;
    }

    to {
        top: -30px;
    }
}

/*Safari and Chrome*/
@-webkit-keyframes carouselmove {
    from {
        top: 0px;
    }

    to {
        top: -30px;
    }
}